<template>
  <v-list>
    <v-list-item :href="createGoogleMapsSearchLink()">
      <v-list-item-avatar>
        <v-icon>
          place
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        {{ formatEventLocation() }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon>
          event
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ formatEventDate() }}</v-list-item-title>
        <v-list-item-subtitle>{{ formatEventStart() }}</v-list-item-subtitle>
        <v-list-item-subtitle>{{ formatEventEnd() }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="event.participantsBroughtHome">
      <v-list-item-avatar>
        <v-icon>
          directions_car
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        Teilnehmer werden nach Hause gebracht.
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="event.registrationPossible">
      <v-list-item-avatar>
        <v-icon>
          euro
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div>
          Preis: <b>{{ formatPrice() }}</b>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="event.registrationPossible">
      <v-list-item-avatar>
        <v-icon>
          info
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div>
          Anmeldefrist:
          <b>{{
            parseDate(event.registrationInformation.registrationDeadline)
          }}</b>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Parser from "@/utils/Parser";

export default {
  name: "EventInfoList",
  props: {
    event: Object
  },
  methods: {
    formatPrice() {
      if (this.event.freeOfCharge) {
        return "kostenlos";
      } else {
        return Parser.numberToString(this.event.price, 2, " €");
      }
    },
    formatEventLocation() {
      if (this.event.locationIndependent) {
        return "Ortsunabhängig";
      }
      return Parser.parseLocation(this.event.eventLocation);
    },
    createGoogleMapsSearchLink() {
      if (this.event.locationIndependent || !this.event.eventLocation) {
        return "";
      }
      let loc = this.event.eventLocation;
      let queryParameters =
        loc.street + "+" + loc.postalCode + "+" + loc.city + "+" + loc.country;
      return encodeURI(
        "https://www.google.com/maps/search/?api=1&query=" + queryParameters
      );
    },
    formatEventDate() {
      if (this.event.startDate === this.event.endDate) {
        return this.parseDate(this.event.startDate);
      } else {
        return (
          "vom " +
          this.parseDate(this.event.startDate) +
          " bis zum " +
          this.parseDate(this.event.endDate)
        );
      }
    },
    formatEventStart() {
      let string = "Treffpunkt: ";
      string += this.event.startTime + " Uhr";
      if (this.event.meetingPointLocationDefined) {
        string += ", " + Parser.parseLocation(this.event.meetingPointLocation);
      }
      return string;
    },
    formatEventEnd() {
      let string = "Ende: ";
      string += this.event.endTime + " Uhr";
      if (this.event.pickupPointLocationDefined) {
        string += ", " + Parser.parseLocation(this.event.pickupPointLocation);
      }
      return string;
    },
    parseDate(date) {
      return Parser.parseDate(new Date(date));
    }
  }
};
</script>

<style scoped></style>
