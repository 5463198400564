<template>
  <v-row class="mx-0 justify-center justify-lg-start" style="width: 100%">
    <v-card class="mt-md-3 mx-md-8 mx-lg-16" width="100%" flat>
      <v-card-title>
        {{ event.name }}
        <v-spacer />
        <v-btn
          v-if="isAdmin() || isLeiter() || isStavo()"
          icon
          @click="csvExportDialog = true"
          class="mr-3"
        >
          <v-icon>
            file_upload
          </v-icon>
        </v-btn>
        <csv-export-dialog
          v-model="csvExportDialog"
          :headers="csvExportHeaders"
          :data="registrations.map(reg => reg.user)"
        />
        <v-btn
          v-if="isAdmin() || isLeiter() || isStavo()"
          icon
          @click="editEvent()"
        >
          <v-icon>
            edit
          </v-icon>
        </v-btn>
        <!--        <v-btn
          v-if="isAdmin() || isLeiter() || isStavo()"
          icon
          @click="deleteEvent(event.id)"
        >
          <v-icon>
            delete
          </v-icon>
        </v-btn>-->
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0 ma-0">
        <event-info-list :event="event" />
        <v-divider />
        <div class="sticky-tabs">
          <v-tabs v-model="tab">
            <v-tab :key="1">
              Übersicht
            </v-tab>
            <v-tab :key="2">
              Ernährung
            </v-tab>
            <v-tab :key="3">
              Medizin
            </v-tab>
            <v-tab :key="4">
              Generell
            </v-tab>
          </v-tabs>
          <v-divider />
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item :key="1">
            <div class="text-h6 ml-3 mt-2">
              Teilnehmer ({{ registrations.length }}):
            </div>
            <v-list>
              <v-list-item v-for="reg in sortedRegistrations" :key="reg.id">
                <v-list-item-avatar>
                  <v-avatar color="primary">
                    <span class="headline white--text mr-2">
                      {{ reg.user.forename.charAt(0) }}
                    </span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <span
                    >{{ reg.user.forename + " " + reg.user.surname }} ({{
                      formatBirthday(reg.user.birthday)
                    }})</span
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="openUserDetailDialog(reg.user.id)">
                    <v-icon>
                      info
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <user-detail-dialog ref="userDetailDialog" />
          </v-tab-item>
          <v-tab-item :key="2">
            <diet-data-tab :registrations="registrations" />
          </v-tab-item>
          <v-tab-item :key="3">
            <medical-data-tab :registrations="registrations" />
          </v-tab-item>
          <v-tab-item :key="4">
            <general-data-tab :registrations="registrations" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import EventInfoList from "@/components/events/EventInfoList";
import { mapActions, mapGetters } from "vuex";
import DietDataTab from "@/components/registration/DietDataTab";
import Parser from "@/utils/Parser";
import UserDetailDialog from "@/components/user/UserDetailDialog";
import MedicalDataTab from "@/components/registration/MedicalDataTab";
import GeneralDataTab from "@/components/registration/GeneralDataTab";
import CsvExportDialog from "@/components/utility/CsvExportDialog";

export default {
  name: "OperationDetails",
  components: {
    CsvExportDialog,
    GeneralDataTab,
    MedicalDataTab,
    UserDetailDialog,
    DietDataTab,
    EventInfoList
  },
  data() {
    return {
      tab: 0,
      csvExportDialog: false,
      csvExportHeaders: [
        { title: "Geschlecht", propName: "gender" },
        { title: "Vorname", propName: "forename" },
        { title: "Nachname", propName: "surname" },
        { title: "Straße", propName: "street" },
        { title: "PLZ", propName: "postalCode" },
        { title: "Ort", propName: "city" },
        { title: "Land", propName: "country" },
        { title: "Geburtstag", propName: "birthday" }
        /*{title: "Personentyp", propName: ""},
        {title: "Teilgenommen von", propName: ""},
        {title: "Teilgenommen bis", propName: ""},
        {title: "Dauer", propName: ""},*/
      ]
    };
  },
  computed: {
    event() {
      let index = this.$store.state.events.events
        .map(event => event.id)
        .indexOf(parseInt(this.$route.params.eventId));
      return this.$store.state.events.events[index];
    },
    eventOperationInformation() {
      return this.$store.state.events.eventsOperationInformation.find(
        operationInfo => operationInfo.eventId === this.event.id
      );
    },
    users() {
      return this.toMap(this.$store.state.users.users);
    },
    registrations() {
      return this.eventOperationInformation.registrations.map(reg => {
        reg.user = this.users.get(reg.userId);
        return reg;
      });
    },
    sortedRegistrations() {
      let registrations = [...this.registrations];
      return registrations.sort(
        (a, b) => new Date(b.user.birthday) - new Date(a.user.birthday)
      );
    }
  },
  methods: {
    ...mapActions("events", [
      "deleteEvent",
      "unregister",
      "refreshEventsOperationInformation"
    ]),
    ...mapActions("users", ["refreshUsers"]),
    ...mapGetters("user", ["isAdmin", "isLeiter", "isStavo"]),
    editEvent() {
      this.$router.push({
        name: "edit-event",
        params: { eventId: this.event.id }
      });
    },
    toMap(users) {
      let map = new Map();
      users
        .filter(member => member.id !== 0)
        .forEach(member => map.set(member.id, member));
      return map;
    },
    formatBirthday(birthday) {
      return Parser.parseDate(new Date(birthday));
    },
    openUserDetailDialog(userId) {
      this.$refs.userDetailDialog.open(userId);
    }
  },
  mounted() {
    this.refreshUsers();
    this.refreshEventsOperationInformation();
  }
};
</script>

<style scoped>
.sticky-tabs {
  position: sticky;
  top: 3rem;
  z-index: 2;
}
</style>
