<template>
  <div>
    <v-data-table :headers="headers" :items="registrations">
      <template v-slot:item.name="item">
        {{ item.item.user.forename }} {{ item.item.user.surname }}
      </template>
      <template v-slot:item.pocketMoney="item">
        <v-simple-checkbox
          :value="item.item.pocketMoneyData.managedPocketMoney"
        />
      </template>
      <template v-slot:item.swimmer="item">
        <v-simple-checkbox :value="item.item.swimmingData.swimmer" />
      </template>
      <template v-slot:item.supervisedSwimming="item">
        <v-simple-checkbox
          :value="item.item.swimmingData.onlySupervisedSwimming"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "GeneralDataTab",
  props: {
    registrations: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        {
          text: "Taschengeld von Leitern verwaltet",
          value: "pocketMoney",
          sortable: false
        },
        { text: "Schwimmer", value: "swimmer", sortable: false },
        {
          text: "Schwimmen nur mit Aufsicht",
          value: "supervisedSwimming",
          sortable: false
        }
      ]
    };
  },
  computed: {
    meatEaters() {
      return this.registrations.filter(reg => reg.dietData.diet === "Fleisch")
        .length;
    },
    vegetarianEaters() {
      return this.registrations.filter(
        reg => reg.dietData.diet === "Vegetarisch"
      ).length;
    },
    veganEaters() {
      return this.registrations.filter(reg => reg.dietData.diet === "Vegan")
        .length;
    }
  }
};
</script>

<style scoped></style>
