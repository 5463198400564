<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-content> Fleisch: {{ meatEaters }} </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          Vegetarisch: {{ vegetarianEaters }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content> Vegan: {{ veganEaters }} </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-data-table :headers="headers" :items="registrations">
      <template v-slot:item.name="item">
        {{ item.item.user.forename }} {{ item.item.user.surname }}
      </template>
      <template v-slot:item.diet="item">
        {{ item.item.dietData.diet }}
      </template>
      <template v-slot:item.intolerances="item">
        <div v-if="item.item.dietData.intolerance">
          {{ item.item.dietData.intoleranceDescription }}
        </div>
        <div v-else>
          _
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "DietDataTab",
  props: {
    registrations: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Ernährung", value: "diet", sortable: false },
        { text: "Unverträglichkeiten", value: "intolerances", sortable: false }
      ]
    };
  },
  computed: {
    meatEaters() {
      return this.registrations.filter(reg => reg.dietData.diet === "Fleisch")
        .length;
    },
    vegetarianEaters() {
      return this.registrations.filter(
        reg => reg.dietData.diet === "Vegetarisch"
      ).length;
    },
    veganEaters() {
      return this.registrations.filter(reg => reg.dietData.diet === "Vegan")
        .length;
    }
  }
};
</script>

<style scoped></style>
