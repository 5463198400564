<template>
  <div>
    <v-data-table :headers="headers" :items="registrations">
      <template v-slot:item.name="item">
        {{ item.item.user.forename }} {{ item.item.user.surname }}
      </template>
      <template v-slot:item.bepanthen="item">
        <v-simple-checkbox :value="item.item.medicalData.bepanthen" disabled />
      </template>
      <template v-slot:item.fenistil="item">
        <v-simple-checkbox :value="item.item.medicalData.fenistil" disabled />
      </template>
      <template v-slot:item.octenisept="item">
        <v-simple-checkbox :value="item.item.medicalData.octenisept" disabled />
      </template>
      <template v-slot:item.precondition="item">
        <div v-if="item.item.medicalData.precondition">
          {{ item.item.medicalData.preconditionDescription }}
        </div>
        <div v-else>
          _
        </div>
      </template>
      <template v-slot:item.medication="item">
        <div v-if="item.item.medicalData.medication">
          <div class="font-weight-light mt-2">Medikation:</div>
          {{ item.item.medicalData.medicationDescription }}
          <v-row no-gutters class="align-center mt-2">
            <v-simple-checkbox
              :value="item.item.medicalData.noSelfMedication"
            />
            <span class="ml-1">Medikation durch das Leitungsteam</span>
          </v-row>
          <div
            v-if="item.item.medicalData.noSelfMedication"
            class="font-weight-light mt-2"
          >
            Medikationsplan:
          </div>
          <div v-if="item.item.medicalData.noSelfMedication">
            {{ item.item.medicalData.medicationPlan }}
          </div>
        </div>
        <div v-else>
          _
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "MedicalDataTab",
  props: {
    registrations: {
      type: Array
    }
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        {
          text: "Bepanthen",
          value: "bepanthen",
          sortable: false,
          width: "100px"
        },
        {
          text: "Fenistil",
          value: "fenistil",
          sortable: false,
          width: "100px"
        },
        {
          text: "Octenisept",
          value: "octenisept",
          sortable: false,
          width: "100px"
        },
        { text: "Vorerkrankung", value: "precondition", sortable: false },
        { text: "Medikation", value: "medication", sortable: false }
      ]
    };
  },
  computed: {
    meatEaters() {
      return this.registrations.filter(reg => reg.dietData.diet === "Fleisch")
        .length;
    },
    vegetarianEaters() {
      return this.registrations.filter(
        reg => reg.dietData.diet === "Vegetarisch"
      ).length;
    },
    veganEaters() {
      return this.registrations.filter(reg => reg.dietData.diet === "Vegan")
        .length;
    }
  }
};
</script>

<style scoped></style>
